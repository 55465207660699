export const config = {

  // API
  api: {
    baseUrl: 'https://staging.apigenie.app/api/v1',
    endpoint: {
      configuration: {
        path: '/configuration/pwa',
      },
    },
  },

};
