import { config } from '../config';

import { Injectable } from '@angular/core';
import {
  HttpClient,
} from '@angular/common/http';

@Injectable({
providedIn: 'root'
})
export class ApiService {

  private baseUrl: string;
  private configurationUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = config.api.baseUrl;
    this.configurationUrl = this.baseUrl + config.api.endpoint.configuration.path;
  }

  public async getConfig(): Promise<object> {
    return await this.httpClient.get(this.configurationUrl, { responseType: 'json' }).toPromise();
  }

  public async postCall(url: string, payload: object): Promise <object> {
    return await this.httpClient.post(url, payload).toPromise();
  }

}
